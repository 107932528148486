import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: lazyLoad("LoginPage")
  }, {
    path: '/logout',
    name: 'Logout',
    component: lazyLoad("LogoutPage")
  }, {
    path: '/logout/:username/:password',
    name: 'LogoutReLogin',
    component: lazyLoad("LogoutPage")
  }, {
    path: '/rating',
    name: 'Rating',
    component: lazyLoad("Rating")
  }, {
    path: '/rating/:username/:token',
    name: 'Rating',
    component: lazyLoad("Rating")
  }, {
    path: '/reminder',
    name: 'Reminder',
    component: lazyLoad("Reminder")
  }, {
    path: '/User',
    component: lazyLoad("User/Base"),
    children: [
      // {
      //   path: '',
      //   name: 'UserProfile',
      //   component: lazyLoad("User/Profile")
      // },
      {
        path: '',
        redirect: '/user/information',
      },
      {
        path: 'information',
        name: 'UserInformation',
        component: lazyLoad("User/Information")
      },
      {
        path: 'password',
        name: 'UserPassword',
        component: lazyLoad("User/Password")
      },
      {
        path: 'reminders',
        name: 'UserReminders',
        component: lazyLoad("User/Reminders")
      },
      {
        path: 'visa_options',
        name: 'UserVisaOptions',
        component: lazyLoad("User/VisaOptions")
      },
      {
        path: 'visa_options/:id',
        name: 'UserVisaOptionsID',
        component: lazyLoad("User/VisaOptionsID")
      },
      {
        path: 'messages',
        name: 'UserMessages',
        component: lazyLoad("User/Messages")
      },
      {
        path: 'subscription',
        name: 'UserSubscription',
        component: lazyLoad("User/Subscription")
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
