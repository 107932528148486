<script>
import moment from 'moment'
var md5 = require('md5');

import { user } from './userData.js';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      needPassword: false,
      tokenPassword: "",
      loading_screen: true,
      account_type: 0, // 0=Free Account, 1=Tourist Insights, 2=Thailand StayGuard:
      screen_mode: "",
      gender: [
        { "id": 1, "name": "Male" },
        { "id": 2, "name": "Female" },
      ],
      rate: [100, 150, 200, 250, 300, 350, 400, 450, 500],
      age_range: [],
      union_status: [],
      delivery_cap: [],
      ethnicities: [],
      languages: [],
      countries: [],
      vocal_type: [],
      // com_type: [],
      demo_cat: [],
      accents: [],
    }
  },
  // created(){
  //   this.age_range = this.LocalStorage("_age_range_",[]);
  //   this.union_status = this.LocalStorage("_union_status_",[]);
  //   this.delivery_cap = this.LocalStorage("_delivery_cap_",[]);
  //   this.ethnicities = this.LocalStorage("_ethnicities_",[]);
  //   this.languages = this.LocalStorage("_languages_",[]);
  //   this.countries = this.LocalStorage("_countries_",[]);
  //   this.vocal_type = this.LocalStorage("_vocal_type_",[]);
  //   this.com_type = this.LocalStorage("_com_type_",[]);
  //   this.demo_cat = this.LocalStorage("_demo_cat_",[]);
  //   this.accents = this.LocalStorage("_accents_",[]);
  // },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    set_session_userData(data) {
      let acc = {
        id: data.id,
        username: data.username,
        password: data.password,
        fname: data.fname,
        lname: data.lname,
        img: data.img,
        token: data.token
      }
      localStorage.setItem("_userData_", JSON.stringify(acc));
    },
    check_login(account) {
      console.log("check_login", account, user);
      if (account.password == user.password) {
        if (account.token == user.token) {
          this.screen_mode = 'active';
        } else {
          this.screen_mode = 'invalid_token';
        }
        setTimeout(() => { this.loading_screen = false; }, 1000);
      } else {
        // this.redirect("/");
        this.logout();
      }
    },
    check_payment(payment) {
      let now = Date.parse(new Date());
      let inputDate = Date.parse(new Date(payment.next_bill));
      if (inputDate < now) this.screen_mode = 'expiration';
    },
    md5(val) {
      return md5(val);
    },
    redirect(path, obj = null) {
      if (this.$route.path !== path) {
        if (obj) {
          this.$router.push({ path: path, query: obj });
        } else {
          this.$router.push(path)
        }
      }
    },
    login_with_token() {
      let tokenData = this.LocalStorage("_tokenData_");
      // let memberData = this.LocalStorage("_memberData_");
      // let accountData = this.LocalStorage("_accountData_");
      // console.log( tokenData );
      // console.log( memberData, accountData );
      if (tokenData.email && tokenData.token && tokenData.type) {
        this.login_token(tokenData);
      }
    },
    async login_token(tokenData) {
      // console.log(tokenData);

      var url = this.api + 'login/token/' + this.secret;
      var data = new FormData();
      data.set('email', tokenData.email);
      data.set('token', tokenData.token);
      data.set('type', tokenData.type);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            let acc = response.data.data;
            this.save_token(acc);
            switch (tokenData.type) {
              case "personal_account":
                // localStorage.setItem("_accountData_",JSON.stringify(acc));
                // this.accountData = acc;
                // this.redirect("/personal");
                this.redirect("/personal/update_profile");
                break;
              case "members":
                // localStorage.setItem("_memberData_",JSON.stringify(acc));
                // this.memberData = acc;
                this.redirect("/members");
                break;
            }
            // this.$swal("Successful Login ","","success");
            this.$emit("close");
          } else {
            this.needPassword = true;
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          this.is_loading = false;
        })
    },
    save_token(data) {
      let tokenData = new Object();
      tokenData.email = data.m_username;
      tokenData.m_img = data.m_img;
      tokenData.token = data.token;
      tokenData.type = data.type;
      localStorage.setItem("_tokenData_", JSON.stringify(tokenData));
    },
    set_data(data, array) {
      if (Array.isArray(data)) return data;

      let Val = [];
      let Data = data.split(",");
      Data.forEach((e) => {
        let index = array.findIndex(x => x.id == e);
        if (index !== -1) Val.push(array[index]);
      });
      return Val
    },
    update_account_data(name, array) {
      let account = this.LocalStorage(name);
      Object.keys(array).forEach((e) => {
        account[e] = array[e];
      });
      localStorage.setItem(name, JSON.stringify(account));
    },
    profile_image(path) {
      if (path == "") path = "../assets/user.jpg";

      return path;
    },
    r_com_type(company) {
      let text = company.com_type;
      if (this.com_type.length) {
        let index = this.com_type.findIndex((e) => parseInt(e.id) == parseInt(company.com_type));
        if (index > -1) return this.com_type[index].name;
      } else {
        return text;
      }
    },
    logout_personal() {
      let acct = this.sessionData;
      if (this.isFreeAccount(acct)) {
        if (acct.paypal_status !== "Approved Profiles" && !parseInt(acct.approval)) {
          this.ask_for_aprroval();
        } else {
          this.ask_for_logout();
        }
      } else {
        this.ask_for_logout();
      }
    },
    ask_for_aprroval() {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to submit your profile \n for approval before logging out?",
        icon: "info",
        closeOnClickOutside: false,
        buttons: {
          Yes: true,
          cancel: "Later",
        },
      })
        .then((value) => {
          console.log(value);
          if (value) {
            this.redirect("/personal/update_profile");
            setTimeout(() => {
              document.getElementById("approval_btn").click()
            }, 500);
          } else {
            this.redirect("/");
          }
        });
    },
    ask_for_logout() {
      this.$swal(`Are you sure you want to logout?`, ``, "warning", { buttons: ['Stay logged in', `Logout`], dangerMode: true })
        .then((ok) => {
          if (ok) {
            this.logout();
          }
        });
    },
    logout() {
      localStorage.setItem("_userData_", JSON.stringify(new Object));
      setTimeout(() => window.location.href = '/', 1);
    },
    uniq_id() {
      var n = Math.floor(Math.random() * 11);
      var k = Math.floor(Math.random() * 1000000);
      var m = String.fromCharCode(n) + k;
      return m;
    },
    isFreeAccount(acct = null) {
      if (!acct) {
        return false;
      } else if (acct.payment_provider == "free_account" && acct.m_img == "") {
        if (parseInt(acct.m_sex) == 1) {
          acct.m_img = "../app/personal_account/profile/maleart.png";
        } else if (parseInt(acct.m_sex) == 2) {
          acct.m_img = "../app/personal_account/profile/femaleart.png";
        }
        let tokenData = this.LocalStorage("_tokenData_");
        acct.type = tokenData.type;
        // tokenData.m_img = acct.m_img;
        this.save_token(acct);

        return true;
      }
      return (acct.payment_provider == "free_account");
    },
    isAccountActive(acct = null) {
      console.log(acct);
      return true;
      // if(!acct)return false;
      //
      // let active = false;
      // let m_class = parseInt(acct.m_class);
      // let m_pay_status = parseInt(acct.m_pay_status);
      // let free_account = this.isFreeAccount(acct);
      //
      // if(free_account){
      //   active=true;
      // }else if(m_class==3){
      //   active=true;
      // }else if(m_pay_status==2 || m_pay_status==5){
      //   active=true;
      // }else{
      //   const minutesToAdjust = 60*24;
      //   const millisecondsPerMinute = 60000;
      //
      //   let now =  new Date();
      //   let today = new Date( now + (minutesToAdjust*millisecondsPerMinute) ).getTime();
      //   // let next_bill = moment(acct.paypal_next_bill).valueOf();
      //   // let m_class_exp = moment(acct.m_class_exp).valueOf();
      //
      //   let payment_provider = acct.payment_provider.toLowerCase();
      //   switch (payment_provider) {
      //     case "paypal":
      //     // console.log(acct.paypal_next_bill,today,moment(acct.paypal_next_bill).valueOf(),today>moment(acct.paypal_next_bill).valueOf());
      //     if(today<moment(acct.paypal_next_bill).valueOf())active=true;
      //     break;
      //     case "stripe":
      //     // console.log(acct.m_class_exp,today,moment(acct.m_class_exp).valueOf(),today>moment(acct.m_class_exp).valueOf());
      //     if(today<moment(acct.m_class_exp).valueOf())active=true;
      //     break;
      //   }
      // }
      // return active;
    },
    r_cat_name(demo) {
      let text = demo.cat_id;
      if (this.demo_cat.length) {
        let index = this.demo_cat.findIndex((e) => parseInt(e.cat_id) == parseInt(demo.cat_id));
        if (index > -1) return this.demo_cat[index].cat_name;
      } else {
        return text;
      }
    },
    async get_com_type() {
      var url = this.api + 'dataOption/get_com_type/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.com_type = response.data.data;
            localStorage.setItem("_com_type_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    removeChars(text) {
      // Use regular expressions to replace parentheses and spaces with an empty string
      return text.replace(/[()\-\s]/g, '');
    },
    Verified_by_MyThaiAdvisor_front() {
      this.$swal(`Verified Company Status`, `Company search listings with a blue badge indicate that MyThaiAdvisor has verified the company’s legal registration in Thailand. Our verification process includes reviewing their official company documents to ensure authenticity and reliability. Companies with this badge have been recognized as established and legitimate businesses within our network.`, "/assets/verify.png")
    },
    Verified_by_MyThaiAdvisor() {
      Swal.fire({
        title: "This Company has been Verified by MyThaiAdvisor.",
        iconHtml: `<img src="/assets/verify.png">`,
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        position: 'top-end',
        customClass: {
          popup: 'verified-company-popup',
        },
      });
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    date(date) {
      return moment(date).format('MMMM Do, YYYY');
    },
    date_message: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a');
    },
    date_project: function (date) {
      return moment(date).format('MMMM Do, YYYY h:mm a') + " (PST)";
    },
    date_approval: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a') + " (PST)";
    },
    date_reminder: function (date) {
      return moment(date).format('D MMMM, YYYY') + " at 12:00 PM (ICT) Bangkok";
    },
    date_reminder_: function (date) {
      return moment(date).format('D MMMM, YYYY') + " at " + moment(date).format('h:mm A') + " (ICT) Bangkok Time";
    },
    date_list: function (date) {
      var fromNow = moment(date).fromNow();
      return moment(date).calendar(null, {
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    }
  }
};
</script>

<style>
.swal-icon--custom {
  width: 75px !important;
}

.verified-company-popup .swal2-icon {
  border: 0px !important;
  margin: 0px !important;
  width: 45px !important;
}

.verified-company-popup .swal2-icon-content img {
  width: 45px !important;
}
</style>